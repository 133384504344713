@import url("https://fonts.googleapis.com/css2?family=Muli:wght@700&display=swap");

body {
  margin: 0;
  font-family: "Muli", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: bold !important;
}

app {
  font-family: "Muli", sans-serif;
  font-weight: bold !important;
}

.form-pupOp{width: 320px !important;}

.MuiFormLabel-root {
  color: rgb(113 113 113 / 58%) !important;
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
  font-weight: bold !important;
}

body,
p,
a,
b,
href,
div,
input,
span,
Button,
td,
tr {
  font-family: "Muli", sans-serif !important;
  font-weight: bold !important;
}

* {
  font-family: "Muli", sans-serif;
  font-weight: bold !important;
}
