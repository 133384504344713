.width-input-process-config {
  width: 65% !important;
}

.place-content-center {
  place-content: center !important;
}

.file-upload {
  background-color: #efefef;
  width: 75%;
  margin: 0 auto;
  padding: 20px;
  height: 300px;
}

.file-upload:hover {
  background-color: #e6e6e6;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 2px dashed #b5b5b5;
  position: relative;
  align-content: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.drag-text {
  text-align: center;
}

.drag-text h4 {
  color: #818181;
}
.width-card-process-upload {
  width: 74% !important;
  margin-bottom: -5px !important;
}
.width-card-mobile {
  width: 100% !important;
  margin-bottom: -5px !important;
}
.width-card-content-process-upload {
  padding: 15px !important;
}
.centered-cards-process-upload {
  width: 101%;
  place-content: center !important;
}

.icon-button-trash {
  cursor: pointer;
  color: #525252;
}

.icon-button-trash:hover {
  cursor: pointer;
  color: #dc0303 !important;
}

.number-asigned {
  color: #2c88d9;
  font-size: 25px;
}

.margin-button-finalize {
  margin-top: 20px;
  margin-bottom: 20px;
}
