@import url("https://fonts.googleapis.com/css2?family=Muli:wght@700&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.loader-size {
  display: flex !important;
  height: 450% !important;
  margin-left: 13px !important;
  margin-top: 60px !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@media screen and (min-width: 646px) {
  .responsive-image-app {
    height: 170% !important;
    background-image: url(https://unicef.agenciaproa.com/media/pages/home/4106938416-1580375975/un0237050-1.jpg);
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@media screen and (max-width: 645px) {
  .responsive-image-app {
    height: 100% !important;
    background-image: url(https://unicef.agenciaproa.com/media/pages/home/4106938416-1580375975/un0237050-1.jpg);
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ui.dimmer {
  z-index: 9999 !important;
  position: fixed !important;
}

$octus-color: #f34a4a;
$primary-color: #c0272a;
$secondary-color: #d9d9d8;
$text-color: white;
$disabled-color: gray;
$buttons: (
  "primary": $primary-color,
  "success": #4caf50,
  "cancel": #dc3545,
  "info": #0063cc,
  "edit": #f0ad4e,
);

.c-text-white {
  color: $text-color !important;
}

@each $name, $color in $buttons {
  .c-icon-#{$name} {
    color: #{$color} !important;
  }

  .c-checkbox-#{$name} {
    color: #{$color} !important;

    &:checked {
      color: #{$color} !important;
    }
  }

  .c-grid-btn-#{$name} {
    color: #{$color} !important;

    &:hover {
      color: white !important;
      background-color: #{$color} !important;
    }
  }

  .c-btn-#{$name} {
    color: white !important;
    box-shadow: none;
    text-transform: none;
    padding: 6px 12px;
    border: 1px solid;
    line-height: 1.5;
    background-color: #{$color} !important;
    border-color: #{$color} !important;
    cursor: pointer !important;
    font: "Roboto", sans-serif;

    & span {
      font-size: 12px !important;
    }

    &:hover,
    :active,
    :focus {
      background-color: saturate($color, 10%) !important;
      border-color: saturate($color, 10%) !important;
    }

    &:disabled {
      background-color: $disabled-color !important;
      border-color: $disabled-color !important;
    }

    &.c-btn-square {
      padding: 8px 10px !important;
    }
  }

  .c-grid-btn-#{$name} {
    color: #{$color} !important;

    &:hover {
      color: white !important;
      background-color: #{$color} !important;
    }
  }
}

//Custom margins
@for $i from 0 through 100 {
  .c-mt-#{$i} {
    margin-top: #{$i}px !important;
  }

  .c-mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }

  .c-ml-#{$i} {
    margin-left: #{$i}px !important;
  }

  .c-mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  .c-wp-#{$i} {
    width: #{$i}px !important;
  }

  .c-mwp-#{$i} {
    min-width: #{$i}px !important;
  }

  .c-hp-#{$i} {
    height: #{$i}px !important;
  }

  .c-p-#{$i} {
    padding: #{$i}px !important;
  }

  .c-pl-#{$i} {
    padding-left: #{$i}px !important;
  }

  .c-m-#{$i} {
    margin: #{$i}px !important;
  }

  .c-m-n-#{$i} {
    margin: -#{$i}px !important;
  }
}

.c-text-center {
  text-align: center !important;
}

.c-text-right {
  text-align: right !important;
}

.c-text-left {
  text-align: left !important;
}

.text-head-right {
  justify-content: end !important;
}

.c-form {
  padding: 10px 15px 20px 15px;
}

.c-none {
  display: none !important;
}

.c-d-inline-block {
  display: inline-block !important;
}

.swal-text {
  text-align: center !important;
  width: 100% !important;
}

.c-text-with-icon {
  display: flex !important;
  align-items: center !important;
}

.c-cursor-pointer {
  cursor: pointer !important;
}

// Grids
.c-grid {
  padding: 10px;
  border-left: 4px solid $primary-color !important;

  .c-grid-header {
    & h2 {
      color: black !important;
    }
  }

  .c-grid-header-filter {
    & h5 {
      color: gray !important;
    }
  }

  & .c-grid-btn {
    float: right;
  }
}

.c-grid-results {
  padding: 10px;

  & thead {
    background-color: $primary-color !important;

    & tr th {
      text-align: center !important;
      line-height: 0.8rem !important;
      color: white;
      text-transform: uppercase !important;
      font-weight: 700;
    }
  }

  & tbody {
    & tr td {
      text-align: center !important;
      text-transform: uppercase !important;
      font-weight: 500;
    }
  }
}

.Mui-selected {
  color: rgb(0, 0, 0) !important;
}

.frm-body {
  background-color: #d8d8d6 !important;
  color: white !important;
  border-radius: unset !important;
  border: none !important;
  /*Para img en el header del form*/
  padding-top: 0px !important;
  margin-top: 10px !important;

  & .frm-btn {
    background-color: #c0272a !important;
    border-color: #c0272a !important;
    border-radius: unset !important;
    text-transform: uppercase !important;
    padding: 15px !important;
    font-weight: 800 !important;
    font-size: 13px !important;
    font-family: "Roboto", sans-serif;
    font-weight: 900;

    &:hover {
      background-color: #d41a1d !important;
    }
  }

  & .incon-input i {
    margin: unset !important;
  }

  & .icon-input,
  .frm-input {
    border: unset !important;
    border-radius: unset !important;
  }
}

.btn-filter-position {
  margin-top: -5px !important;
}
.pagination {
  li {
    button {
      &:hover,
      :active {
        background-color: $octus-color !important;
        color: white !important;
      }
    }
  }
}
.c-wp-row {
  input,
  textarea {
    width: 300px !important;
  }
}

.c-wp-input {
  input,
  textarea {
    width: 383px !important;
  }
}
