@media (min-width: 700px){
    .rtc-row{
        grid-template-columns: repeat(2,1fr) !important;
    }
    .btn-search-filter {
        width: 190px !important;
        margin: 5px !important;
        float: right !important;
      }
    .cont-modalBill-rebill-viewProfesionalList{
        width: 550px !important;
    }
    .cont-modalBill-rebill-selectProfesionalList{
        width: 650px !important;
    }
    .container-profesional-info{grid-template-columns: repeat(2, 1fr);}
    .content-filter-list{flex-direction: row;}
    .contBtnHeaderSection{flex-direction: row;}
    .filter-input{width: 220px !important;}
    .IntegrationSessionList{width: 50% !important;}
    .container-inputsParams{flex-direction: row;}
    .container-inputsParams{align-items: center;}
    .generalContainer-params{grid-template-columns: repeat(2,1fr);}
    .cm-charge{right: -100px;}  
    .instructiveIcon, 
    .iconsIntegration, 
    .cm-charge{
        right: -60px;
        bottom: 10px;
    }
    .cont-modalBill-rebill-detail{
        height: 420px;
    }
    .cont-default-modal--expansion{
        grid-template-columns: 250px 450px;
    }
    .contProfileData{
        gap: 30px;
        grid-template-columns: repeat(2, 1fr) !important; 
        place-content: center;
    }
    .column-info-account{
        max-height: 650px;
        overflow: auto;
    }
    .cont-inicio-c1__bgDiv{
        height: 70%;
    }
    .cont-inicio-c1-presentation-indicators{
        max-width: 800px;
        width: 100%;
    }
    .cont-inicio-c1-presentation-indicators-cont-buttons{
        display: none;
    }
    .MuiDialog-paperWidthSm{
        overflow-y: visible !important;
    }
    .cont-inicio-c2__button{
        width: 280px;
        height: 80px;
    }
    .cont-inicio-c3{
        grid-template-columns: repeat(2,1fr);
    }
    .cont-inicio-c4-associateToOctus--flex{
        display: flex;
        justify-content: end;
    }
    .cont-inicio-c2-info-info__title{
        font-size: 2.5em;
    }
    .cont-inicio-c2-info-info__text{
        font-size: 2em;
    }
    .cont-inicio-c5-info-prop-box{
        padding: 50px 20px;
    }
    .cont-inicio-c5-info-prop-box-buttons{
        justify-content: start;
    }
    .cont-inicio-c5-info-prop-box-buttons__button{
        flex-shrink: 0;
    }
    .cont-benefict-c1-info__title, 
    .cont-benefict-c3-info__title, 
    .cont-benefict-c5-info__title{
        font-size: 3.2em;
    }
    .cont-benefict-c1-info-text__title, 
    .cont-benefict-c3-info-text__title, 
    .cont-benefict-c5-info-text__title{
        font-size: 6em;
    }
    .cont-benefict-c2-box, 
    .cont-benefict-c4-box, 
    .cont-benefict-c6-box{
        flex-direction: row;
        align-items: center;
    }
    .cont-benefict-c2-box__title, 
    .cont-benefict-c4-box__title, 
    .cont-benefict-c6-box__title{
        padding: 30px;
        font-size: 3em;
        width: 50%;
        margin: 0;
        text-align: left;
    }
    .cont-benefict-c2-box-info, 
    .cont-benefict-c4-box-info, 
    .cont-benefict-c6-box-info{
        padding: 30px;
        width: 50%;
        font-size: 1.2em;
        margin: 0;
    }
    .cont-contact-c1-info__text{
        font-size: 2em;
    }
    .cont-benefict-c1-info, 
    .cont-benefict-c3-info, 
    .cont-benefict-c5-info{
        height: 600px;
    }
    .cont-benefict-c1-info{
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }
    .cont-benefict-c2-box-info_text, 
    .cont-benefict-c4-box-info_text, 
    .cont-benefict-c5-box-info_text, 
    .cont-benefict-c6-box-info_text{
        line-height: 1.9em;
    }
    .cont-card-prop-info-details__text--bColor{
        font-size: 1.6em;
        line-height: 1.3em;
    }
    .cont-contact-c2-footer-box1__text{
        max-width: 350px;
    }
    .cont-contact-c2-footer-box2--flex{
        flex-direction: row;
    }
    .cont-contact-c2-footer-box2-info{
        align-items: flex-start;
    }
    .cont-contact-c2-footer-box2-logo{
        width: 80px;
        height: 80px;
    }
    .cont-newsList{
        width: 400px;
    }
    .cont-contact-c2-footer-box2-info__wpp__img{
        width: 25px;
        height: 25px;
    }
    .cont-receipt--grid:nth-child(1){
        grid-template-columns: repeat(3, 1fr);
    }
    .cont-receipt--grid:nth-child(2){
        grid-template-columns: repeat(2, 1fr);
        z-index: 50;
    }
    .cont-receipt--grid:nth-child(3){
        grid-template-columns: repeat(3, 1fr);
    }
    .cont-receipt--grid:nth-child(4){
        grid-template-columns: none;
    }
    .contModalShowDetailNews{
        width: 650px !important;
    }
}
@media (min-width: 1130px){
    .MuiGrid-spacing-xs-1{width: calc(100% + 8px) !important;}
    .quantityAndSessions{flex-direction: row;}
    .listTypeOfSessions{margin: 0 30px 30px 60px !important;}
    .instructiveIcon, .iconsIntegration{right: -130px;}
    .cm-charge{right: -180px;}
    .presentacionInicial{height: max-content;}
     .rtc-row{
        grid-template-columns: repeat(3,1fr) !important;
    }
}
@media (min-width: 1024px){
    .notPermission{flex-direction: row;}
    .notPermission-contImg, .notPermission-contInfo{
        width: 50%;
    }
    .cont-contact-c1-mapa__img{
        width: 80%;
    }
    .cont-contact-c2-footer-box1__text{
        max-width: 350px;
        text-align: center;
    }
    .cont-benefict-c2-box__title, 
    .cont-benefict-c4-box__title, 
    .cont-benefict-c6-box__title{
        padding: 0;
        text-align: center;
        font-size: 1.6em;
    }
    .cont-benefict-c2-box-info, 
    .cont-benefict-c4-box-info, 
    .cont-benefict-c6-box-info{
        padding: 0;
    }
    .cont-contact-c1-info__text{
        font-size: 1.5em;
    }
    .cont-benefict-c2-box-info_text, 
    .cont-benefict-c4-box-info_text, 
    .cont-benefict-c5-box-info_text, 
    .cont-benefict-c6-box-info_text{
        font-size: 1em;
    }
    .cont-card-prop-info--flex{
        flex-direction: row;
        align-items: center;
    }
    .cont-card-prop-info__title{
        text-align: center;
    }
    .cont-inicio-c2{
        margin-top: 85px;
    }
    .cont-inicio-c2-info{
        margin-bottom: 60px;
    }
    .cont-benefict-c2-box__title, 
    .cont-benefict-c4-box__title, 
    .cont-benefict-c6-box__title{
        font-size: 1.7em;
    }
    .cont-inicio-c2-info-logoOctus{
        max-width: 350px;
        height: 110px;
    }
    .cont-inicio-c4-associateToOctus-info{
        max-width: 340px;
    }
    .cont-inicio-c2-info-info__title, 
    .cont-inicio-c2-info-info__text{
        font-size: 1.6em;
    }
    .cont-card-prop-info-details__text,
    .cont-card-prop-info-details__text--bColor{
        font-size: 1.4em;
    }
    .cont-inicio-c4-associateToOctus__text,
    .cont-inicio-c4-associateToOctus__text-redColor{
        font-size: 1.4em;
    }
    .cont-inicio-c2__button{
        width: 220px;
        height: 60px;
    }
    .cont-inicio-c4-associateToOctus{
        max-width: 450px;
        width: 65%;
        justify-self: end;
    }
    .cont-inicio-c2-info-info{
        margin: 20px;
        max-width: 750px;
    }
    .cont-inicio-c3{
        margin: 100px auto;
        padding: 50px;
    }
    .cont-inicio-c4-associateToOctus--flex{
        display: block;
        width: auto;
        border: 1px solid #c9c9c9;
        box-shadow: 25px 25px 12px -10px rgb(191 191 191 / 75%);
        border-radius: 10px;
        padding: 20px;
    }
    .cont-benefict-c1-info__title, 
    .cont-benefict-c3-info__title, 
    .cont-benefict-c5-info__title{
        font-size: 1.6em;
    }
    .cont-inicio-c2, 
    .cont-inicio-c3, 
    .cont-inicio-c4, 
    .cont-inicio-c5, 
    .cont-benefict-c3, 
    .cont-benefict-c5, 
    .cont-contact-c1{
        margin-top: 80px;
    }
    .cont-inicio-c2__button{
        margin-top: 0;
    }
    .cont-inicio-c5-props{
        height: max-content;
    }
    .cont-inicio-c3-cont-aditionalInfo-box__benefict{
        width: 450px;
    }
    .cont-inicio-c5-box-cards{
        grid-template-columns: repeat(2, 1fr);
        top: -200px;
    }
    .cont-inicio-c5-box-cards__box:nth-child(2){
        transform: translateY(20%);
    }
    .cont-contact-c1-info{
        position: absolute;
        right: 50%;
        bottom: 0;
        transform: translate(60%, -50%);
    }
    .cont-inicio-c3-aditionalInfo__title{
        font-size: 2.5em;
    }
    .cont-inicio-c3-cont-aditionalInfo-box__img{
        width: 40px;
        height: 40px;
    }
    .cont-inicio-c3-cont-aditionalInfo-box__benefict{
        font-size: 1.4em;
    }
    .cont-benefict-c1-box-logo, 
    .cont-benefict-c3-box-logo, 
    .cont-benefict-c5-box-logo{
        max-width: 580px;
    }
    .cont-benefict-c1-info-text__buttonDown, 
    .cont-benefict-c3-info-text__buttonDown, 
    .cont-benefict-c5-info-text__buttonDown{
        height: 60px;
    }
    .cont-arrow-down--color{
        font-size: 1.5em !important;
    }
    .cont-benefict-c1-info-text__title, 
    .cont-benefict-c3-info-text__title, 
    .cont-benefict-c5-info-text__title{
        font-size: 5em;
    }
    .cont-inicio-c3-info-persona{
        height: 440px;
        width: auto;
        left: 50px;
    }
    .cont-benefict-c1-info-text__subTitle, 
    .cont-benefict-c3-info-text__subTitle, 
    .cont-benefict-c5-info-text__subTitle{
        font-size: 1.6em;
    }
    .cont-benefict-c3-info-text__subText{
        font-size: 1.2em;
    }
    .cont-inicio-c3-info-persona__img{
        border-radius: 10px;
        object-fit: contain;
        border-radius: 10px;
    }
    .cont-inicio-c3-aditionalInfo{
        left: -50px;
        bottom: -50px;
        border-radius: 10px;
        box-shadow: 25px 25px 12px -10px rgb(191 191 191 / 75%);
        -webkit-box-shadow: 25px 25px 12px -10px rgb(191 191 191 / 75%);
        -moz-box-shadow: 25px 25px 12px -10px rgb(191 191 191 / 75%);
    }
    .cont-benefict-c1-info, 
    .cont-benefict-c3-info, 
    .cont-benefict-c5-info{
        height: max-content;
        padding: 25px 0;
    }
    .cont-benefict-c1-box, 
    .cont-benefict-c3-box,
    .cont-benefict-c5-box{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        place-content: center;
        margin-top: 90px;
        max-width: 1400px;
    }
    .cont-inicio-c4{
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }
    .cont-contact-c2-footer-box2{
        flex-direction: row;
    }
    .cont-benefict-c2-box, 
    .cont-benefict-c4-box, 
    .cont-benefict-c6-box{
        padding: 30px 40px;
        justify-content: space-around;
    }
}
@media (min-width: 1200px){
    .rtc-row{
        grid-template-columns: repeat(4,1fr) !important;
    }
    .cont-inicio-c5-box-cards__box{
        width: max-content;
    }
    .cont-menu-navbar{
        display: none;
    }
    .cont-inicio-c4-associateToOctus{
        max-width: 550px;
    }
    .cont-menu-navbar-box{
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        position: relative;
        width: max-content;
        height: max-content;
        background-color: transparent;
        margin-right: 15px;
    }
    .cont-menu-navbar-box-list{
        flex-direction: row;
        height: min-content;
        margin: 0;
    }
    .cont-menu-navbar-box-list-btn__link,
    .cont-menu-navbar-box-list-btn.loginBtn > .cont-menu-navbar-box-list-btn__link{
        font-size: 1em;
        color: #000;
    }
    .cont-menu{
        left: 50%;
        transform: translate(-50%, 0);
        -webkit-transform: translate(-50%, 0);
        -moz-transform: translate(-50%, 0);
        -ms-transform: translate(-50%, 0);
        -o-transform: translate(-50%, 0);
    }
    .cont-menu-navbar-box-list-btn{
        width: auto;
        margin: 10px;
        padding: 8px;
    }
    .cont-main-landing{
        max-width: 1400px;
        margin: auto;
        display: grid;
        place-content: center;
    }
    .cont-benefict-c2-box, 
    .cont-benefict-c4-box, 
    .cont-benefict-c6-box{
        justify-content: center;
    }
    .cont-inicio-c5{
        grid-template-columns: repeat(2, 1fr);
        margin: 150px auto;
        margin-bottom: 0;
        place-items: unset;
    }
    .cont-inicio-c5-props-box{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
    .cont-inicio-c5-props{
        height: 100%;
    }
    .cont-inicio-c5-props__img{
        filter: brightness(0.5);
        -webkit-filter: brightness(0.5);
        border-bottom-left-radius: 0;
        border-top-right-radius: 60px;
    }
    .cont-inicio-c5-info-prop-box-buttons{
        flex-wrap: wrap;
    }
    .cont-inicio-c5-box-cards{
        top: -80px;
    }
}
@media (max-width: 912px){
    .content-filter-list{ flex-direction: row;}
    .styleContent-app{padding: 80px 25px !important;}
    .margin-right-component-sidebar{margin-left: 0 !important;}
    .contProfileData{
        grid-template-columns: repeat(auto-fill, 70%) !important; 
        place-content: center;
    }
    .contentBtn {
        margin: 0 10px !important;
    }
}
@media (max-width: 700px){
    .contProfileData{grid-template-columns: repeat(auto-fill, 95%) !important; place-content: center;}
    .IntegrationSessionList{width: 100% !important;}
    .contModalGrid, .contPreviewInstallment, .contModalAmount{grid-template-columns: 1fr;}
    .container-inputsParams{flex-direction: row;}
    .contProfileData{
        grid-template-columns: repeat(auto-fill, 95%) !important; 
        place-content: center;
    }
}
@media (max-width: 390px){
    .contPracticeColumns{
        display: flex;
        flex-direction: column;
        width: 100% !important;
    }
    .ui.container{margin: 0 !important;}
    .styleContent-app{padding: 80px 10px !important;}
    .MuiCardContent-root{padding: 10px !important;}
    .container-inputsParams{flex-direction: column;}
}

