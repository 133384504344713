@import url("https://fonts.googleapis.com/css2?family=Muli:wght@700&display=swap");

body::-webkit-scrollbar {
  width: 8px;
}
body::-webkit-scrollbar-thumb {
  background-color: #ef4f5aed;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #f21827;
}
.css-1wa3eu0-placeholder {
  color: hsl(0deg 0% 72%) !important;
}
.letter-title-table {
  text-align: center !important;
  width: 10% !important;
}
.p-cell {
  padding: 10px !important;
  text-align: center !important;
}
html {
  scroll-behavior: smooth;
}
.disabled-input {
  background-color: #f2f2f2 !important;
}
.loader-fixed {
  width: 100% !important;
  height: 100% !important;
  position: fixed !important;
}
.MuiTableCell-head b {
  color: rgb(0, 0, 0) !important;
}
.MuiTableCell-root {
  padding: 12px !important;
  border: none !important;
  width: auto !important;
}
.ui.table thead th {
  background-color: transparent !important;
}
.MuiTableHead-root,
.color-letter-inTable {
  background-color: #f05c5c !important;
}

.MuiTableRow-root:nth-child(even) {
  background-color: #eaeaea;
  transition: all 0.2s ease-out;
}

.MuiTableRow-root:nth-child(even):hover {
  transform: scale(0.997);
  box-shadow: 0 0 3px 1px rgba(5, 5, 5, 0.5);
}

tbody tr {
  transition: all 0.2s ease-out;
}

tbody tr:hover {
  transform: scale(0.997);
  box-shadow: 0 0 3px 1px rgba(5, 5, 5, 0.5);
}

thead tr:hover {
  background-color: transparent;
}

.rowDeleted {
  background-color: #d7d7d7 !important;
  position: relative;
}
.rowDeleted::after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: #f05c5c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  -webkit-transform: translate(-50%, 50%);
  -moz-transform: translate(-50%, 50%);
  -ms-transform: translate(-50%, 50%);
  -o-transform: translate(-50%, 50%);
}
.btn-red {
  background: #d20000;
  color: white;
}
.p-cell .letter-title-table {
  text-align: center !important;
}
.letter-title-table b {
  color: white !important;
}
.sticky-header {
  position: sticky;
  top: 0;
  z-index: 900;
  background-color: #f05c5c;
}
.table-container-liquidacion {
  height: 80vh;
  overflow: auto;
}
.btn-red :hover {
  background: #d20000;
  color: white;
}
.errorNotificationInput {
  border: 1px solid rgb(255, 83, 83) !important;
  border-radius: 6px !important;
}
.errorNotificationInputDatePicker .MuiInputBase-root {
  border: 1px solid rgb(255, 83, 83) !important;
  border-radius: 6px !important;
}
.MuiDialog-paper {
  max-width: 680px !important;
}
.MuiDialog-paperWidthSm {
  width: auto !important;
  /* overflow-y: visible !important; */
  position: fixed !important;
}
.MuiDialogContent-root::-webkit-scrollbar {
  width: 3px !important;
  border-radius: 4px;
}
.MuiDialogContent-root::-webkit-scrollbar-thumb {
  border: 3px solid rgb(255, 0, 0) !important;
  background-color: rgb(255, 0, 0) !important;
}
.skiptranslate {
  display: none !important;
}
.btn-circle-blue {
  color: #7297ff !important;
}
.btn-circle-orange {
  color: #f2711c9e !important;
}
.btn-circle-yellow {
  color: #dab41fa8 !important;
}
.btn-circle-green {
  color: #219621ad !important;
}
.btn-circle-red {
  color: rgba(253, 33, 33, 0.501) !important;
}

.btn-circle-blue:hover {
  background: #7297ff !important;
  color: white !important;
}
.btn-circle-orange:hover {
  background: #f2711c9e !important;
  color: white !important;
}
.btn-circle-yellow:hover {
  background: #dab41fa8 !important;
  color: white !important;
}
.btn-circle-green:hover {
  background: #219621ad !important;
  color: white !important;
}
.btn-circle-red:hover {
  background: rgba(253, 33, 33, 0.501) !important;
  color: white !important;
}
.btn-octus {
  background-color: #f05c5c !important;
  color: "white" !important;
}
.btn-octus .ui.button {
  background-color: #f05c5c !important;
  color: "white" !important;
}
.btn-octus :hover {
  background-color: #c44747 !important;
  color: "white" !important;
}
.btn-octus :active {
  background-color: #ed5757 !important;
  color: "white" !important;
}
.custom-hover,
.custom-hover-green,
.custom-hover-view {
  width: min-content;
  color: #7297ff !important;
  padding: 8px !important;
  padding: 8px !important;
  height: max-content;
  font-size: 1.45em !important;
  font-size: 1.45em !important;
  margin: 0 5px !important;
  cursor: pointer !important;
  cursor: pointer !important;
}
.custom-hover:hover,
.custom-hover-green:hover {
  color: #ffff !important;
  background-color: #7297ff !important;
  transform: scale(1.15);
}
.custom-hover-view:hover {
  transform: scale(1.15);
  background-color: transparent !important;
}
.custom-hover-green {
  color: #219621ad !important;
}
.custom-hover-green:hover {
  background-color: #219621ad !important;
  color: #ffff !important;
}
.btn-circle-disabled {
  color: rgb(187, 187, 187) !important;
}
.MuiTableCell-root {
  font-family: "Muli", sans-serif !important;
  font-weight: bold !important;
}
body,
p,
a,
b,
href,
div,
input,
span,
button,
td,
tr,
header {
  font-family: "Muli", sans-serif !important;
  font-weight: bold !important;
}

.MuiFormLabel-root {
  color: rgb(113 113 113 / 58%) !important;
  padding: 0;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.error-text {
  color: rgb(175, 0, 0) !important;
}

.ellipsis-table {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 250px;
}

* {
  font-family: "Muli", sans-serif;
  font-weight: bold !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.margin-left-auto {
  margin-left: auto !important;
}

.class-error-select > div {
  border-color: red !important;
  border: 2px solid red !important;
  border-radius: 5px !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.notPermission {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.notPermission-contInfo {
  padding: 20px;
  text-align: center;
}
.notPermission-contInfo__title {
  font-size: 2em;
  font-weight: 800;
}
.notPermission-contInfo__text {
  font-size: 1.2em;
}
.notPermission-contImg {
  height: 550px;
}
.notPermission-contImg__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}
@media screen and (min-width: 646px) {
  .responsive-image-app {
    height: 170% !important;
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@media screen and (max-width: 645px) {
  .responsive-image-app {
    height: 100% !important;
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.swal2-container {
  z-index: 99999 !important;
  background-color: #14141461 !important;
}
.swal2-html-container {
  font-size: 0.9em;
  color: #434041;
  font-weight: 300 !important;
  text-align: center;
}
.swal2-title {
  text-align: center;
  font-size: 1.5em;
}
.MuiDialog-paper {
  max-width: calc(600px + 100%) !important;
}

.editorText::-webkit-scrollbar {
  width: 2px;
}
.editorText::-webkit-scrollbar-thumb {
  border: 2px solid rgb(255, 0, 0);
}
.status-icons {
  margin-left: 5px;
}
.cont-status-info {
  display: flex;
  margin: 0 4px !important;
  padding: 4px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.btn-new-filter,
.btn-back-filter {
  width: auto;
  margin: 5px !important;
  background-color: rgba(32, 149, 32, 0.726) !important;
  color: white !important;
}
.btn-search-filter {
  width: 190px !important;
  height: max-content !important;
  margin: 5px !important;
  margin-right: auto !important;
}
.status-icon-verified {
  border: 1px solid #4caf50;
}
.status-icon-envoiced {
  border: 1px solid #4caf50;
}
.status-icon-notVerified {
  border: 1px solid #f05c5c;
}
.status-icon-pending {
  border: 1px solid #f0ad4e;
}
.btn-search-filter-users {
  width: 190px !important;
  margin: 6px 0 0px 24px !important;
}
.btn-filter-fact {
  width: 124px;
  margin: 5px !important;
  color: white !important;
  float: right !important;
}
.container-profesional-info {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;
  align-items: flex-start;
  max-width: 2000px;
}
.container-profesional-info__columns {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: space-between;
  max-width: 700px;
}
.styleContent-app {
  padding: 90px 40px 50px 140px;
  position: relative;
}
.btn-search-filter {
  float: right !important;
}
/* .MuiGrid-spacing-xs-1 {
  width: 100% !important;
  display: inline-block;
} */
.btn-notification {
  background-color: transparent;
  padding: 6px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: background-color 0.2s ease;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
}
.btn-notification:hover {
  background-color: #7297ff;
}
.btn-notification:hover .newsNotificationIcon {
  color: white !important;
}

.margin-right-component-sidebar {
  margin-left: 70px !important;
}

.animation-closed-sideber {
  transition: margin-left 0.3s ease !important;
}

.content-filter-list {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.content-filter-list-nuevo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.cont-newsList {
  width: 300px;
}
.cont-gnralNews-allReady {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.cont-gnralNews-allReady__title {
  align-self: self-start;
  font-size: 0.9em;
  margin-bottom: 0;
  color: red;
  padding: 10px;
}
.cont-gnralNews-allReady-info,
.cont-gnralNews-allReady-info--noCenter {
  display: flex;
  background-color: #f8f8f8;
  border-top: 1px solid #e1e1e1;
  padding: 5px 0;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.cont-gnralNews-allReady-info--noCenter {
  align-items: start;
}
.cont-gnralNews-allReady__btnNews {
  width: 100%;
  border: unset;
  font-size: 0.9em;
  border-top: 1px solid #e1e1e1;
  border-bottom: unset;
  background-color: transparent;
  padding: 10px 0;
  cursor: pointer;
  text-align: center;
  transition: background-color 0.5s ease;
  -webkit-transition: background-color 0.5s ease;
  -moz-transition: background-color 0.5s ease;
  -ms-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
}
.cont-gnralNews-allReady__btnNews:hover {
  background-color: #e1e1e1;
}
.menuForNotification {
  padding: 0 !important;
}
.editorText {
  max-width: 600px !important;
  height: max-content !important;
}
.editorText.onlyShowEditorText .ql-toolbar.ql-snow,
.editorText.onlyShowEditorText .ql-container.ql-snow {
  border: unset !important;
}
.header-section {
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  margin-bottom: 18px;
}
.titleAndVideo {
  margin-right: 6px;
  display: inline-flex;
  align-items: center;
}
.contBtnHeaderSection {
  display: flex;
  flex-direction: column;
}
.grid-cont,
.contModalGrid,
.contPreviewInstallment,
.contModalAmount {
  display: grid;
  gap: 10px;
}
.MuiPaper-elevation4 {
  box-shadow: none !important;
}
.MuiAppBar-colorDefault {
  background-color: transparent !important;
}
.PrivateTabIndicator-colorPrimary-8 {
  background-color: #f05c5c !important;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
}
.MuiTabs-scrollable {
  border-radius: 6px !important;
  border-left: 2px solid #f05c5c !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
}
.cont-default-modal {
  overflow: visible !important;
  height: 100%;
}
.MuiDialogContent-root {
  padding: 5px 24px !important;
  display: grid !important;
  flex: unset !important;
  gap: 15px !important;
  min-width: 350px !important;
  z-index: 300 !important;
}
.contPreviewInstallment {
  grid-template-columns: repeat(2, minmax(50%, 1fr));
}
.instructiveIcon,
.iconsIntegration {
  position: absolute;
  right: -5px;
  bottom: 50px;
}
.iconsIntegrationMobile {
  position: absolute;
  right: -84px;
  bottom: 4px;
}

.instructiveIconMobile {
  position: absolute;
  right: -40px;
  bottom: 4px;
}

.contProfileData {
  display: grid !important;
  gap: 10px;
}
.contProfileData-block {
  height: 100%;
  width: 100% !important;
  padding: 0 !important;
}
.MuiPickersModal-dialog:first-child {
  padding: 0 !important;
}
.quantityAndSessions {
  display: flex;
  flex-direction: column;
}
.listTypeOfSessions {
  margin: 30px 30px 30px 0 !important;
}
.IntegrationSessionList {
  width: 100% !important;
}
.filter-input {
  margin: 8px !important;
}
.column-info-account::-webkit-scrollbar {
  width: 3px !important;
}
.column-info-account::-webkit-scrollbar-thumb {
  background-color: #f59797 !important;
  border-radius: 6px !important;
  -webkit-border-radius: 6px !important;
  -moz-border-radius: 6px !important;
  -ms-border-radius: 6px !important;
  -o-border-radius: 6px !important;
}

.generalContainer-params {
  display: grid;
  grid-template-columns: 1fr;
  max-width: 1800px;
}
.container-configParams {
  margin: 25px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 550px;
}
.container-inputsParams {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.container-inputsParams__item {
  width: 190px;
  max-width: 380px;
}

/* LANDING PAGE STYLES*/
.cont-inicio {
  position: relative;
}
.gnral-styles-img,
.cont-inicio-c2-info-logoOctus__img,
.cont-inicio-c1-presentation-indicators__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}
.cont-menu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  z-index: 200;
  position: fixed;
  top: 0;
  background-color: #ffffffd5;
}
.cont-menu-navbar {
  margin-right: 25px;
}
.cont-menu-navbar-box-list-btn__link {
  font-size: 1.5em;
  color: #ffff;
  position: relative;
  display: inline-block;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cont-menu-navbar-box-list-btn__link--icon {
  margin-right: 5px;
  display: inline-block;
}
.cont-menu-logo.openMenu {
  color: #ffff;
}
.cont-menu-logo {
  height: 80px;
  padding: 5px;
  margin: 0;
  display: flex;
  margin-left: 25px;
  align-items: center;
}
.cont-menu-logo__text {
  font-size: 1.2em;
  margin-left: 10px;
}
.cont-menu-logo__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}
.cont-menu-navbar__menu {
  width: 35px;
  height: 30px;
  margin: 10px 10px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  background-color: transparent;
  border: none;
}
.cont-menu-navbar__menu span {
  background-color: #e16973;
  position: absolute;
  border-radius: 2px;
  transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  width: 100%;
  height: 4px;
  transition-duration: 500ms;
  -webkit-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  -moz-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  -ms-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
  -o-transition: 0.3s cubic-bezier(0.8, 0.5, 0.2, 1.4);
}
.cont-menu-navbar-box {
  position: fixed;
  top: 0;
  right: 0;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  z-index: -1;
  background-color: #151515f7;
  display: grid;
  place-content: center;
  transform: translateX(100%);
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transition: transform 0.5s ease;
  -webkit-transition: transform 0.5s ease;
  -moz-transition: transform 0.5s ease;
  -ms-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
}
.cont-menu-navbar-box.openMenuNavBar {
  transition: transform 0.5s ease;
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  -webkit-transition: transform 0.5s ease;
  -moz-transition: transform 0.5s ease;
  -ms-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
}
.cont-menu-navbar-box-list-btn {
  margin: 20px;
  padding: 15px;
  list-style: none;
  text-align: center;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  transition: background-color 0.5s ease;
  -webkit-transition: background-color 0.5s ease;
  -moz-transition: background-color 0.5s ease;
  -ms-transition: background-color 0.5s ease;
  -o-transition: background-color 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cont-menu-navbar-box-list {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin: 30px 0;
  padding: 0;
}
.cont-menu-navbar-box-list-btn.loginBtn > .cont-menu-navbar-box-list-btn__link {
  color: #ffff;
}
.cont-menu-navbar-box-list-btn:hover,
.cont-menu-navbar-box-list-btn:focus {
  background-color: #e16973;
}
.cont-menu-navbar-box-list-btn:hover .cont-menu-navbar-box-list-btn__link,
.cont-menu-navbar-box-list-btn:focus .cont-menu-navbar-box-list-btn__link {
  color: #ffff !important;
}
.cont-menu-navbar-box-list-btn.loginBtn {
  border: 1px solid #e16973;
}
.cont-menu-navbar__menu span:nth-child(1) {
  top: 0px;
  left: 0px;
}
.cont-menu-navbar__menu span:nth-child(2) {
  top: 10px;
  left: 0px;
  opacity: 1;
}
.cont-menu-navbar__menu span:nth-child(3) {
  bottom: 5px;
  left: 0px;
}
.cont-menu-navbar__menu:not(.openMenu):hover span:nth-child(1) {
  transform: rotate(-3deg) scaleY(1.1);
  -webkit-transform: rotate(-3deg) scaleY(1.1);
  -moz-transform: rotate(-3deg) scaleY(1.1);
  -ms-transform: rotate(-3deg) scaleY(1.1);
  -o-transform: rotate(-3deg) scaleY(1.1);
}
.cont-menu-navbar__menu:not(.openMenu):hover span:nth-child(2) {
  transform: rotate(3deg) scaleY(1.1);
}
.cont-menu-navbar__menu:not(.openMenu):hover span:nth-child(3) {
  transform: rotate(-4deg) scaleY(1.1);
}
.cont-menu-navbar__menu.openMenu span:nth-child(1) {
  transform: rotate(45deg);
  top: 13px;
}
.cont-menu-navbar__menu.openMenu span:nth-child(2) {
  opacity: 0;
}
.cont-menu-navbar__menu.openMenu span:nth-child(3) {
  transform: rotate(-45deg);
  top: 13px;
}
.cont-arrow-down--color {
  color: #e16973;
  font-size: 2.5em !important;
  animation: landingPageArrowDown 1s infinite linear;
  -webkit-animation: landingPageArrowDown 1s infinite linear;
}
.gnral-button-styles,
.cont-inicio-c1-presentation-indicators-cont-buttons__button,
.cont-inicio-c2__button {
  padding: 10px;
  text-align: center;
  position: relative;
  color: #ffff;
  background-color: #e16973;
  font-size: 1.3em;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  border: none;
  margin: 20px 10px;
}
.cont-inicio-c1 {
  position: relative;
}
.cont-inicio-c1__bgDiv {
  background-color: #ac1918;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 70%;
  border-bottom-left-radius: 90px;
  border-bottom-right-radius: 90px;
  z-index: -1;
}
.cont-inicio-c1-presentation {
  position: relative;
  display: grid;
  place-items: center;
  overflow: hidden;
}
.cont-inicio-c1-presentation-indicators::before {
  content: "";
  background-image: url("./components/landingPage/images/octusBubbles.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 180px;
  position: absolute;
  top: 0;
  left: 0;
}
.cont-inicio-c1-presentation-indicators {
  margin: 0 auto;
  padding: 50px 0;
  position: relative;
  width: 55%;
  max-width: 400px;
}
.cont-inicio-c1-presentation-indicators-cont-buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  right: -70px;
  margin: auto;
}
.cont-inicio-c3 {
  max-width: 1400px;
}
.cont-inicio-c2,
.cont-inicio-c3,
.cont-inicio-c4,
.cont-inicio-c5,
.cont-benefict-c1,
.cont-benefict-c3,
.cont-benefict-c5,
.cont-contact-c1 {
  position: relative;
  display: grid;
  place-items: center;
  margin-top: 100px;
}
.cont-inicio-c2 {
  margin-top: 50px;
}
.cont-inicio-c2__button {
  width: 200px;
  height: 70px;
  font-size: 2.2em;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  letter-spacing: 2.2px;
  cursor: pointer;
  transition: transform 0.5s ease;
  -webkit-transition: transform 0.5s ease;
  -moz-transition: transform 0.5s ease;
  -ms-transition: transform 0.5s ease;
  -o-transition: transform 0.5s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cont-inicio-c2__button:hover,
.cont-inicio-c2__button:focus {
  transform: scale(0.85);
  -webkit-transform: scale(0.85);
  -moz-transform: scale(0.85);
  -ms-transform: scale(0.85);
  -o-transform: scale(0.85);
  transition: transform 0.5 ease;
  -webkit-transition: transform 0.5 ease;
  -moz-transition: transform 0.5 ease;
  -ms-transition: transform 0.5 ease;
  -o-transition: transform 0.5 ease;
  box-shadow: 0px 0px 20px 10px rgb(253, 131, 131);
  color: #ffff;
}
.cont-inicio-c2__button::before {
  content: "";
  background-image: url("./components/landingPage/images/octusBubblesRed.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  width: 60px;
  height: 180px;
  position: absolute;
  top: -130px;
  right: -50px;
}
.cont-card-prop-info--flex {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding: 0;
}
.cont-inicio-c2-info {
  display: grid;
  place-items: center;
  margin-bottom: 90px;
}
.cont-inicio-c2-info-logoOctus {
  margin: 15px 0;
  width: 90%;
  height: 200px;
  max-width: 500px;
}
.cont-inicio-c2-info-info {
  text-align: center;
  width: 100%;
  margin: auto;
  max-width: 750px;
}
.cont-inicio-c2-info-info__title {
  font-size: 2em;
  font-weight: 800;
  color: #434041;
}
.cont-inicio-c3 {
  grid-template-columns: 1fr;
}
.cont-inicio-c2-info-info__text {
  line-height: 1.4em;
  font-weight: 200;
  font-size: 1.2em;
  color: #808080;
}
.cont-inicio-c3-info-persona {
  width: 100%;
  position: relative;
  height: 100%;
  margin: 0;
}
.cont-inicio-c3-info-persona__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.cont-inicio-c3-aditionalInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #e16973;
  width: 100%;
  height: 100%;
  padding: 10px;
  position: relative;
}
.cont-inicio-c3-aditionalInfo__title {
  font-size: 3.4em;
  color: #ffff;
  font-weight: 800;
  margin-bottom: 15px;
  letter-spacing: 1.2px;
}
.cont-inicio-c3-cont-aditionalInfo {
  position: relative;
  display: grid;
  place-content: center;
}
.cont-inicio-c3-cont-aditionalInfo-box {
  display: flex;
  align-items: center;
  margin: 20px 10px;
}
.cont-inicio-c3-cont-aditionalInfo-box__img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 10px;
}
.cont-inicio-c3-cont-aditionalInfo-box__benefict {
  background-color: #ffff;
  width: 250px;
  max-width: 400px;
  flex-shrink: 0;
  font-size: 1.8em;
  color: #282c34;
  padding: 10px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.cont-inicio-c4-associateToOctus {
  width: 100%;
  max-width: 550px;
  margin: 0;
  position: relative;
}
.cont-inicio-c4-associateToOctus__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.cont-inicio-c4-associateToOctus-info {
  max-width: 340px;
  text-align: left;
  padding: 12px;
}
.cont-inicio-c4-associateToOctus__text {
  font-size: 1.8em;
  color: #282c34;
  line-height: 1.5em;
}
.cont-inicio-c4-associateToOctus__text-redColor,
.cont-inicio-c5-info-prop-box-title {
  font-size: 2.8em;
  color: #e16973;
  font-weight: bolder;
}
.cont-inicio-c5 {
  background-color: #ac1918;
}
.cont-inicio-c5-props {
  width: 100%;
  height: 90vh;
  margin: 0;
  position: relative;
}
.cont-inicio-c5-props__img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
}
.cont-inicio-c5-info-prop-box {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.cont-inicio-c5-info-prop-box-buttons {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.cont-inicio-c5-info-prop-box-buttons__button {
  border: 2px solid #ffff;
  background-color: transparent;
  color: #ffff;
  padding: 10px;
  font-size: 2.2em;
  margin: 8px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}
.cont-inicio-c5-info-prop-box-title {
  text-align: right;
  font-size: 3em;
  max-width: 400px;
}
.cont-inicio-c5-box-cards {
  display: grid;
  place-items: center;
  z-index: 10;
  position: relative;
  top: -200px;
}
.cont-inicio-c5-box-cards__box {
  margin: 10px;
  height: max-content;
}
.cont-inicio-c5 {
  place-items: unset;
}
.cont-card {
  background-color: #ffff;
  padding: 25px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 400px;
  max-height: max-content;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  box-shadow: 0px 0px 15px 1px #434041;
}
.cont-card-prop-logo {
  width: 50px;
  height: 50px;
  margin: 0;
  flex-shrink: 0;
  align-self: end;
}
.cont-card-prop-logo__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: block;
}
.cont-card-prop-info__title {
  font-weight: bold;
  font-size: 1.5em;
  color: #1a1a1a;
}
.cont-card-prop-info-details {
  margin-top: 20px;
}
.cont-card-prop-info-details__text {
  color: #434041;
  line-height: 1.5em;
  font-size: 1.4em;
}
.cont-card-prop-info-details__text--bColor {
  font-size: 1.4em;
  font-weight: bold;
  color: #1a1a1a;
}
.cont-benefict-c1,
.cont-benefict-c3,
.cont-benefict-c5 {
  margin: 0;
}
.cont-benefict-c1-info,
.cont-benefict-c3-info,
.cont-benefict-c5-info {
  background: #e52d27; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #b31217, #e52d27); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #b31217,
    #e52d27
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  width: 100%;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  height: 500px;
  max-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  position: relative;
}
.cont-benefict-c1-info__title,
.cont-benefict-c3-info__title,
.cont-benefict-c5-info__title {
  font-size: 2.5em;
  color: #ffff;
  text-align: center;
  max-width: 700px;
  margin-bottom: 50px;
}
.cont-benefict-c1-box,
.cont-benefict-c3-box,
.cont-benefict-c5-box {
  top: -80px;
  position: relative;
}
.cont-benefict-c1-box-logo,
.cont-benefict-c3-box-logo,
.cont-benefict-c5-box-logo,
.cont-contact-c1-mapa {
  margin: 0;
  position: relative;
}
.cont-benefict-c5-box-logo::after {
  background-image: url("./components/landingPage/images/octusBeneficio_3.png");
}
.cont-benefict-c1-box-logo__img,
.cont-benefict-c3-box-logo__img,
.cont-benefict-c5-box-logo__img,
.cont-contact-c1-mapa__img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}
.cont-benefict-c1-info-text,
.cont-benefict-c3-info-text,
.cont-benefict-c5-info-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cont-benefict-c1-info-text__title,
.cont-benefict-c3-info-text__title,
.cont-benefict-c5-info-text__title {
  font-size: 4.5em;
  color: #e16973;
  margin: 0;
  position: relative;
  display: block;
}
.cont-benefict-c1-info-text__title::before,
.cont-benefict-c3-info-text__title::before,
.cont-benefict-c5-info-text__title::before {
  content: "";
  background-image: url("./components/landingPage/images/octusBubblesRed.png");
  background-repeat: no-repeat;
  background-position: center;
  width: 100px;
  height: 180px;
  position: absolute;
  top: -130px;
  right: 0px;
}
.cont-benefict-c1-info-text__subTitle,
.cont-benefict-c3-info-text__subTitle,
.cont-benefict-c5-info-text__subTitle {
  font-size: 2.2em;
  color: #e16973;
  text-align: center;
}
.cont-benefict-c3-info-text__subText {
  font-size: 1.5em;
  font-weight: 300;
  text-align: center;
  color: #4d4d4d;
}
.cont-benefict-c1-info-text__buttonDown,
.cont-benefict-c3-info-text__buttonDown,
.cont-benefict-c5-info-text__buttonDown {
  border: 1px solid #e16973;
  height: 100px;
  text-align: center;
  background-color: transparent;
  border-radius: 90px;
  -webkit-border-radius: 90px;
  -moz-border-radius: 90px;
  -ms-border-radius: 90px;
  -o-border-radius: 90px;
}
.cont-benefict-c2,
.cont-benefict-c4,
.cont-benefict-c6 {
  display: grid;
  place-content: center;
}
.cont-benefict-c2-box,
.cont-benefict-c4-box,
.cont-benefict-c6-box {
  display: flex;
  flex-direction: column;
  padding: 40px;
}
.cont-benefict-c2-box:nth-child(odd),
.cont-benefict-c4-box:nth-child(odd),
.cont-benefict-c6-box:nth-child(odd) {
  background-color: #dddddd;
}
.cont-benefict-c2-box__title,
.cont-benefict-c4-box__title,
.cont-benefict-c6-box__title {
  color: #e16973;
  font-size: 2.5em;
}
.cont-benefict-c2-box-info,
.cont-benefict-c4-box-info,
.cont-benefict-c6-box-info {
  padding: 5px;
}
.cont-benefict-c2-box-info_text,
.cont-benefict-c4-box-info_text,
.cont-benefict-c5-box-info_text,
.cont-benefict-c6-box-info_text {
  color: #434041;
  line-height: 1.5em;
  font-size: 1.2em;
}
.cont-benefict-c2-box-info_text b,
.cont-benefict-c4-box-info_text b,
.cont-benefict-c6-box-info_text b,
.cont-benefict-c5-box-info_text b {
  color: #000000;
}
.cont-benefict-c3-box-logo__img:nth-child(2),
.cont-benefict-c3-box-logo__img:nth-child(3),
.cont-benefict-c3-box-logo__img:nth-child(4),
.cont-benefict-c5-box-logo__img:nth-child(2) {
  position: absolute;
  bottom: 0;
  left: -10px;
  width: 150px;
  height: 60px;
}
.cont-benefict-c3-box-logo__img:nth-child(3) {
  bottom: 60px;
}
.cont-benefict-c3-box-logo__img:nth-child(4) {
  bottom: 120px;
}
.cont-benefict-c5-info {
  background: #000000; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #434343, #000000); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #434343,
    #000000
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
.cont-contact-c1-info {
  max-width: 550px;
  padding: 10px;
  margin: 20px auto;
  position: relative;
}
.cont-contact-c1-info::before,
.cont-contact-c1-info::after {
  content: "";
  position: absolute;
  background-color: gray;
  width: 100%;
  height: 2px;
}
.cont-contact-c1-info::before {
  top: -5px;
  left: 0;
}
.cont-contact-c1-info::after {
  bottom: -5px;
  left: 0;
}
.cont-contact-c1-info__text {
  font-size: 1.5em;
  line-height: 1.4em;
}
.cont-contact-c1-mapa {
  overflow: hidden;
}
.cont-contact-c1-mapa__img {
  width: 150%;
}
.cont-contact-c2 {
  display: grid;
  place-content: center;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  background-color: #ac1918;
  margin-top: 80px;
}
.cont-contact-c2-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.cont-contact-c2-footer-box1 {
  text-align: center;
  max-width: 600px;
}
.cont-contact-c2-footer-box1__title {
  color: #ffff;
  font-size: 1.6em;
}
.cont-contact-c2-footer-box2--flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cont-contact-c2-footer-box1__text {
  font-size: 1.4em;
  text-align: center;
  color: #ffff;
  margin: 14px 0 !important;
}
.cont-contact-c2-footer-box2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.cont-contact-c2-footer-box2-logos {
  display: flex;
  align-items: center;
  margin: 20px;
}
.cont-contact-c2-footer-box2-logo {
  margin: 0 15px;
  width: 60px;
  height: 60px;
}
.cont-contact-c2-footer-box2-logo__img {
  width: 100%;
  height: 100%;
  display: block;
}
.cont-contact-c2-footer-box2-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}
.cont-contact-c2-footer-box2-info a,
.cont-contact-c2-footer-box2-info p {
  margin: 10px;
}
.cont-contact-c2-footer-box2-info__link,
.cont-contact-c2-footer-box2-info__wpp,
.cont-contact-c2-footer-box2-info__email {
  color: #ffff;
  font-size: 1.2em;
  cursor: pointer;
  position: relative;
}
.cont-contact-c2-footer-box2-info__wpp {
  display: flex;
  align-items: center;
}
.cont-contact-c2-footer-box2-info__wpp__img {
  width: 40px;
  height: 40px;
  object-fit: contain;
  display: block;
  margin-right: 5px;
}
.cont-contact-c2-footer-box2-info__link:hover,
.cont-contact-c2-footer-box2-info__wpp:hover,
.cont-contact-c2-footer-box2-info__email:hover {
  color: #ffff;
}
.cont-contact-c2-footer-box2-info__link:hover.cont-contact-c2-footer-box2-info__link::after,
.cont-contact-c2-footer-box2-info__wpp:hover.cont-contact-c2-footer-box2-info__wpp::after,
.cont-contact-c2-footer-box2-info__email:hover.cont-contact-c2-footer-box2-info__email::after {
  transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -ms-transform: scaleX(1);
  -o-transform: scaleX(1);
}
.cont-inicio-versiones {
  max-width: 75%;
  margin: auto;
}
.cont-inicio-c3,
.cont-contact-c1 {
  margin-top: 25px !important;
}
.cont-inicio-c3 {
  margin-bottom: 25px !important;
}
.cont-contact-c2 {
  margin-top: 30px !important;
}
.cont-inicio-c4,
.cont-benefict-c3,
.cont-benefict-c5 {
  margin-top: 0 !important;
}
.cont-benefict-c2,
.cont-benefict-c4,
.cont-benefict-c6 {
  margin-top: -55px !important;
}
.cont-inicio-c5 {
  margin-top: 100px !important;
}
.padding-r {
  padding-right: 1.5em !important;
}
/* FIN LANDING PAGE STYLES */

@keyframes landingPageArrowDown {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }
}
.customLabelSelect {
  position: absolute;
  top: -15px;
  color: #adadad;
  left: 5px;
  background-color: #ffff;
  padding: 0 6px;
  z-index: 10;
  font-size: 0.85em;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.contModalGrid {
  grid-template-columns: repeat(2, minmax(290px, 1fr));
  overflow-y: scroll !important;
}
.cont-default-modal--grid {
  grid-template-columns: 1fr;
  gap: 22px !important;
}
.cont-modalBill-rebill {
  display: grid;
  overflow: auto !important;
  gap: 20px;
}
.cont-modalBill-rebill:not(.cont-default-modal--expansion) {
  overflow: visible !important;
}
.cont-modalBill-rebill-info {
  display: grid;
  gap: 20px;
}
.cont-modalBill-rebill-detail {
  overflow: auto;
  height: 350px;
  border-radius: 8px;
}
.cont-modalBill-rebill-selectProfesionalList {
  overflow-x: auto;
}
.cont-modalBill-rebill-selectProfesionalList__amountInput {
  width: 180px !important;
  max-width: 200px !important;
}
.cont-modalBill-rebill-viewProfesionalList,
.cont-modalBill-rebill-selectProfesionalList {
  height: max-content;
}
.cont-receipt,
.cont-receipt--grid {
  display: grid !important;
  align-items: center;
  gap: 32px;
}
.cont-infoProfessional-select {
  position: relative !important;
  margin: 5px !important;
}
.cont-infoProfessional-select:nth-child(1) {
  z-index: 60 !important;
}
.cont-infoProfessional-select:nth-child(2) {
  z-index: 50 !important;
}
.cont-infoProfessional-select:nth-child(3) {
  z-index: 40 !important;
}
.cont-infoProfessional-select:nth-child(4) {
  z-index: 30 !important;
}
.cont-infoProfessional-select:nth-child(5) {
  z-index: 20 !important;
}
.cont-infoProfessional-select:nth-child(6) {
  z-index: 11 !important;
}
.cont-infoProfessional-select:nth-child(7) {
  z-index: 10 !important;
}
.signalInfoDescount {
  display: flex;
  align-items: center;
  position: absolute;
  right: -50px;
  top: 0;
}
.MuiTabs-scrollable .MuiTabs-indicator {
  background-color: #f05c5c !important;
}
.newsNotificationIcon {
  font-size: 2em;
  position: relative;
  cursor: pointer;
  color: #000000;
}
.newsNotificationIcon--animationShake {
  animation: newsNotificationShake 1.4s infinite linear;
  -webkit-animation: newsNotificationShake 1.4s infinite linear;
}
@keyframes newsNotificationShake {
  0% {
    transform: rotate(0deg) scale(1, 1);
  }
  10% {
    transform: rotate(7deg) scale(1.2, 1.2);
  }
  20% {
    transform: rotate(-7deg) scale(1.2, 1.2);
  }
  30% {
    transform: rotate(7deg) scale(1.2, 1.2);
  }
  40% {
    transform: rotate(-7deg) scale(1.2, 1.2);
  }
  50% {
    transform: rotate(7deg) scale(1.2, 1.2);
  }
  60% {
    transform: rotate(-7deg) scale(1.2, 1.2);
  }
  70% {
    transform: rotate(7deg) scale(1.2, 1.2);
  }
  80% {
    transform: rotate(-7deg) scale(1.2, 1.2);
  }
  90% {
    transform: rotate(7deg) scale(1.2, 1.2);
  }
  100% {
    transform: rotate(0deg) scale(1, 1);
  }
}

.cont-gnralNews {
  display: flex;
  flex-direction: column;
  align-items: start !important;
  padding: 0 !important;
}
.cont-gnralNews.viewAllNews {
  padding: 15px 8px !important;
  width: 100%;
  border-bottom: 1px solid #c9c9c9a8;
}
.cont-generalNews-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.cont-generalNews-info__title,
.cont-generalNews-info__text {
  font-size: 0.9em;
  margin-bottom: 0;
}
.cont-generalNews-info__text,
.cont-generalNews__notification {
  color: #636363;
}
.cont-generalNews__notification {
  font-size: 0.8em;
}
.cont-generalNews__Emptynotification {
  font-size: 0.9em;
  color: #6e6e6e;
  text-align: center;
}
.contModalShowDetailNews {
  height: 100% !important;
}
.cont-AmountPracticeList {
  overflow-y: auto !important;
}
.validateAffiliateBtn,
.preFormAuthBtn {
  background-color: transparent !important;
  padding: 10px !important;
  font-size: 0.9em !important;
  height: min-content !important;
}
.validateAffiliateBtn {
  border: 1px solid #7297ff !important;
  color: #7297ff !important;
}
.validateAffiliateBtn:hover {
  background-color: #7297ff !important;
  color: #ffff !important;
}
.preFormAuthBtn {
  border: 1px solid #5db25d !important;
  color: #5db25d !important;
}
.preFormAuthBtn:hover {
  background-color: #5db25d !important;
  color: #ffff !important;
}
.contHeaderAndBtnDocument {
  position: relative;
  margin: 10px 0 !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.rtc-row {
  gap: 30;
  display: grid !important;
  grid-template-columns: 1fr;
}
.btn-circle-disabled--color {
  color: #adadad !important;
}
.summaryTotalAmountsLiquidation--flex {
  display: flex;
  margin: 10px;
}
.summaryTotalAmountsLiquidation--flex__amount {
  margin: 0 6px;
}
.summaryTotalAmountsLiquidation--green {
  color: #31ef06ea;
}
.summaryTotalAmountsLiquidation--red {
  color: #fd3b3b;
}

.min-grilla {
  padding: 7px 3px !important;
}
.rowCell {
  padding: "10px !important";
  text-align: "center !important";
}

.width-numFactura {
  width: 6em !important;
}