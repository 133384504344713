.contNotes{
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    background: #e52d27;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #b31217, #e52d27);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #b31217, #e52d27); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}
.MuiTypography-body1::-webkit-scrollbar{
    height: 4px !important;
}
.MuiTypography-body1::-webkit-scrollbar-thumb{
    border: 3px solid rgb(253, 114, 114) !important;
    background-color: rgb(252, 128, 128);
}

