.centered-filter-data {
  place-content: center !important;
}

.size-date-picke-filter input {
  padding: 10.7px 0px 10.7px 14px !important;
}

.margin-input-filters {
  margin: 5px !important;
}

.align-select {
  display: inline-block !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.btn-edit-header{
  width: auto;
  margin: 5px !important;
  color: #ffff !important;
  background-color: #f2711c9e !important;
}

.letter-title-table {
  font-size: 15px !important;
  color: #6d6d6d !important;
}
.width-column-filters {
  width: 33.33% !important;
  text-align: center;
}

.color-border-dash {
  border-left: 4px solid #f05c5c !important;
  width: 100% !important;
}

@media screen and (min-width: 690px) {
  .btn-new-filter, .btn-edit-header {
    float: right !important;
  }
}

@media screen and (max-width: 960px) {
  .width-column-filters {
    width: 50% !important;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .center-responsive-filters {
    text-align: center;
  }
}

@media screen and (max-width: 690px) {
  .width-column-filters {
    width: 100% !important;
    text-align: center;
  }
}
