.mostrarModalReturn{
    transform: translate(-50%,-170% ) !important;
    transition: transform .6s ease;
    -webkit-transform: translate(-50%,-170% ) !important;
    -moz-transform: translate(-50%,-170% ) !important;
    -ms-transform: translate(-50%,-170% ) !important;
    -o-transform: translate(-50%,-170% ) !important;
}
.modalTextReturn{
  position: absolute;
  top: 0;
  left: 50%;
  z-index: 200;
  width: max-content;
  padding: 6px;
  border-radius: 4px;
  background-color: #f8d7da;
  transform: translate(-50%,-400%);
  transition: transform .6s ease;
  -webkit-transform: translate(-50%,-400%);
  -moz-transform: translate(-50%,-400%);
  -ms-transform: translate(-50%,-400%);
  -o-transform: translate(-50%,-400%);
  cursor: pointer;
  border: 1px solid transparent;
}
.searchReturn{
  position: relative;
  color: #ffff;
}
.modalTextReturn:hover{
  border-color: #efaeb5;
}
.searchReturn{
  position: relative;
  color: #ffff;
}              
.modalTextReturn:hover .searchReturn:before{
    animation-name: surprise;
        -webkit-animation-name: surprise;
        -moz-animation-name: surprise;
    animation-duration: .8s;
        -webkit-animation-duration: .8s;
        -moz-animation-duration: .8s;
}
@keyframes surprise
{
    0% {transform: rotate(0deg) scale(1,1);;}
    10% {transform: rotate(7deg) scale(1.2,1.2);}
    20% {transform: rotate(-7deg) scale(1.2,1.2);}
    30% {transform: rotate(7deg) scale(1.2,1.2);}
    40% {transform: rotate(-7deg) scale(1.2,1.2);}
    50% {transform: rotate(7deg) scale(1.2,1.2);}
    60% {transform: rotate(-7deg) scale(1.2,1.2);}
    70% {transform: rotate(7deg) scale(1.2,1.2);}
    80% {transform: rotate(-7deg) scale(1.2,1.2);}
    90% {transform: rotate(7deg) scale(1.2,1.2);}
    100% {transform: rotate(0deg) scale(1,1);}
}

.searchReturn::before{
  position: absolute;
  top: -65%;
  right: -10px;
  height: 18px;
  width: 18px;
  border-radius: 9999px;
  background-color: #f05c5c !important;
  content: attr(totalReturns) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .85em;
}