.centered-filter-data {
  place-content: center !important;
}
.commentReturn{
  padding: 10px;
  outline: none;
  resize: none;
  color: #7f8081;
  border-color: #e0e1e2;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}
.contModalComment{
  position: relative;
}
.contModalComment::before{
  content: "!";
  position: absolute;
  font-size: .8em;
  width: 20px;
  height: 20px;
  top: 0;
  right: -5px;
  text-align: center;
  background-color: #f05c5c;
  color: #ffff;
  border-radius: 9999px;
  -webkit-border-radius: 9999px;
  -moz-border-radius: 9999px;
  -ms-border-radius: 9999px;
  -o-border-radius: 9999px;
}
.contModalComment:hover::before{
  animation-name: surprise;
        -webkit-animation-name: surprise;
        -moz-animation-name: surprise;
    animation-duration: .8s;
        -webkit-animation-duration: .8s;
        -moz-animation-duration: .8s;
}
@keyframes surprise
{
    0% {transform: rotate(0deg) scale(1,1);;}
    10% {transform: rotate(7deg) scale(1.2,1.2);}
    20% {transform: rotate(-7deg) scale(1.2,1.2);}
    30% {transform: rotate(7deg) scale(1.2,1.2);}
    40% {transform: rotate(-7deg) scale(1.2,1.2);}
    50% {transform: rotate(7deg) scale(1.2,1.2);}
    60% {transform: rotate(-7deg) scale(1.2,1.2);}
    70% {transform: rotate(7deg) scale(1.2,1.2);}
    80% {transform: rotate(-7deg) scale(1.2,1.2);}
    90% {transform: rotate(7deg) scale(1.2,1.2);}
    100% {transform: rotate(0deg) scale(1,1);}
}
.size-date-picke-filter input {
  padding: 10.7px 0px 10.7px 14px !important;
}

.size-date-picke-filter .MuiIconButton-root {
  padding: 0;
}


.margin-input-filters {
  margin: 5px !important;
}

.align-select {
  display: inline-block !important;
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.btn-new-filter, .btn-back-filter {
  width: auto;
  margin: 5px !important;
  background-color: rgba(32, 149, 32, 0.726) !important;
  color: white !important;
}
.btn-back-filter{
  flex-shrink: 0;
  background-color: #adadadb9 !important;
}
.letter-title-table {
  font-size: 15px !important;
  color: #6d6d6d !important;
}
.width-column-filters {
  width: 33.33% !important;
  text-align: center;
}

.color-border-dash {
  border-left: 4px solid #f05c5c !important;
  width: 100% !important;
}

@media screen and (min-width: 690px) {
  .btn-new-filter, .btn-edit-header {
    float: right !important;
  }
}

@media screen and (max-width: 960px) {
  .width-column-filters {
    width: 50% !important;
    text-align: center;
  }
}

@media screen and (max-width: 500px) {
  .center-responsive-filters {
    text-align: center;
  }
}

@media screen and (max-width: 690px) {
  .width-column-filters {
    width: 100% !important;
    text-align: center;
  }
}
