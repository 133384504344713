
@keyframes shadowPulse {
    33% {
      background: #FFF;
      box-shadow: -24px 0 #ef4f5b, 24px 0 #FFF;
    }
    66% {
      background: #ef4f5b;
      box-shadow: -24px 0 #FFF, 24px 0 #FFF;
    }
    100% {
      background: #FFF;
      box-shadow: -24px 0 #FFF, 24px 0 #ef4f5b;
    }
  }