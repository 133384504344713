.loginForm {
  width: 100%;
}

.color-letter-error {
  color: #de0202;
}

.new-password-text {
  cursor: pointer;
  color: #737b82;
}

.new-password-text :hover {
  color: #4891d0 !important;
}

.color-border-login {
  border-left: 4px solid #f05c5c !important;
}
