.table-container {
  width: 100%;
  overflow-x: hidden;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
  border: 1px solid #aeaeae6e;
  margin: auto;
}

.table-row:nth-child(even) {
  background-color: #eaeaea; /* Gris claro */
}

.table-row:nth-child(odd) {
  background-color: white; /* Fondo blanco */
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background-color: #f05c5c;
  color: white;
  text-align: center;
}

.table-cell-head {
  padding: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-cell {
  text-align: center;
  padding: 10px;
  max-width: 75px; /* Límite de ancho */
  white-space: nowrap; /* Evita que el texto pase a otra línea */
  overflow: hidden; /* Oculta el texto que excede */
  text-overflow: ellipsis; /* Muestra "..." si el texto es muy largo */
}

/* Solo aplica en el caso de móvil */
.mobile-ops {
  width: 15%; /* Opciones */
}

.mobile-social {
  width: 60%; /* Obra Social */
}

.mobile-alias {
  width: 25%; /* Alias */
}
