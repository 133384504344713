.ui.thin.left.sidebar,
.ui.thin.right.sidebar {
  width: 85px !important;
}
.background-sidebar {
  border-right: 2px solid #dfe6ed !important;
  background: #ffffff !important;
  box-shadow: none !important;
}

.ui.labeled.icon.menu .item {
  min-width: 4em;
  flex-direction: column;
}

.icon-sidebar {
  cursor: pointer;
  font-weight: 400 !important;
  color: #afafaf;
  border-left: 3px solid transparent !important;
}

.icon-sidebar-selected {
  cursor: pointer;
  font-weight: 400 !important;
  color: #f05c5c !important;
  background-color: #eaeaea !important;
  border-left: 3px solid #f05c5c !important;
  transition: all .15s ease;
  -webkit-transition: all .15s ease;
  -moz-transition: all .15s ease;
  -ms-transition: all .15s ease;
  -o-transition: all .15s ease;
}

.icon-menu {
  font-weight: 400 !important;
}

.icon-sidebar:hover {
  cursor: pointer;
  font-weight: 400 !important;
  color: #f05c5cdb;
  background-color: #eaeaea !important;
  border-left: 3px solid #f05c5c !important;
  transition: all .15s ease;
}

.sidebar-closed {
  width: 73px !important;
}

.sidebar-open {
  width: 172px !important;
}

.sidebar-closed-movile {
  width: 0px !important;
}

.sidebar-closed p {
  opacity: 0;
}

.sidebar-open p {
  opacity: 1;
}

.sidebar-closed svg {
  margin-left: 6px;
}

.sidebar-open svg {
  margin-left: 0;
}

.animation {
  transition: width 0.3s ease !important;
};
