.icon-clear-date-filter {
  cursor: pointer;
  margin: 10px;
  color: #aaa;
}

.icon-clear-date-filter:hover {
  color: rgb(177, 0, 0) !important;
}

.btn-search-filter-period {
  width: 190px !important;
  margin: 6px 0 0px 24px !important;
}

.btn-add-filter {
  width: 124px;
  margin: 5px !important;
}

@media screen and (min-width: 690px) {
  .btn-add-filter {
    width: 124px;
    margin: 5px !important;
    float: right !important;
  }
}
