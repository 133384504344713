.border-error-input fieldset {
    border-color: hsl(0deg 100% 50%) !important;
    border-width: 2px !important;
  }

@media screen and (min-width: 500px) {
  .width-input-form {
      min-width: 265px !important;
  }
}
