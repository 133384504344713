  .border-error-input fieldset {
    border-color: hsl(0deg 100% 50%) !important;
    border-width: 2px !important;
  }

  .width-select-form {
    min-width: 267px !important;
  }

  .width-select-form-Mobile {
    min-width: 192px !important;
  }