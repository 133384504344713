.width-auto {
    width: auto !important;
}

.background-header-authenticated {
    border-bottom: 2px solid #dfe6ed !important;
    background: #ffffff !important;
    box-shadow: none !important;
}

.btn-logout {
    background-color: black !important;
    margin-right: 0.5rem !important;
    color: white !important;
    border-color: white !important;
    border-width: 2px !important;
    border-style: solid !important;
}

    .btn-logout:hover {
        background-color: red !important;
        margin-right: 0.5rem !important;
        color: white !important;
        border-color: red !important;
        border-width: 2px !important;
        border-style: solid !important;
    }
