.color-btn-green {
  flex-shrink: 0;
  background-color: #308430 !important;
}

.color-btn-green:hover {
  background-color: #256425 !important;
}
.width-input-form-doc {
  width: 100px !important;
}
.margin-input-quantity {
  margin-right: 180px !important;
  margin-bottom: 20px !important;
}

#column-patient-one {
  width: 50% !important;
}

#column-patient-two {
  width: 50% !important;
}

#column-medical-order-one {
  width: 50% !important;
}

#column-medical-order-two {
  width: 50% !important;
}

#column-practice-info {
  width: 50% !important;
}

#column-practice-date {
  width: 50% !important;
}

.error-btn-sessions {
  background-color: rgb(189, 3, 3) !important;
}

.margin-buttons {
  margin-left: auto;
  margin: 20px 20px 20px 20px;
  float: right;
}

.text-error {
  color: rgb(189, 3, 3) !important;
}

.block-view {
  background: black;
  position: absolute;
  padding: 100%;
  z-index: 999;
  margin-top: -27px;
}

.width-column-btn-doc {
  width: 33.33% !important;
  text-align: center;
}

#row-btn-form {
  width: 50% !important;
}

.table-container {
  width: 100%;
  overflow-x: auto;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  border-radius: 0.5rem;
  border: 1px solid #aeaeae6e;
}

.table-row:nth-child(even) {
  background-color: #eaeaea;
}

.table-row:nth-child(odd) {
  background-color: white;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table-header {
  background-color: #f05c5c;
  color: white;
  text-align: center;
}

.table-cell-head {
  min-width: 80px;
  padding: 12px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-cell {
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
}

@media screen and (max-width: 960px) {
  .width-column-btn-doc {
    width: 50% !important;
    text-align: center;
  }
}

@media screen and (max-width: 690px) {
  .width-column-btn-doc {
    width: 100% !important;
    text-align: center;
  }
}

@media screen and (max-width: 760px) {
  #column-patient-one, #column-patient-two,
  #column-medical-order-one, #column-medical-order-two,
  #column-practice-info, #column-practice-date
   {
    width: 50% !important;
  }
}

@media screen and (max-width: 630px) {
  #column-patient-one, #column-patient-two,
  #column-medical-order-one, #column-medical-order-two,
  #column-practice-info, #column-practice-date{
    width: 100% !important;
  }
}
