.contentBtn{
    display: flex;
    align-items: center;
    margin: 0 10px 0 80px;
}
.btn__label{margin-left: 5px;}
.btn{
    width: 30px;
    height: 20px;
    position: relative;
    cursor: pointer;
    border: none;
    background: transparent;
}

.btn__item{
    background: #201f1f;
    width: 100%;
    height: 2px;
    border-radius: 5px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.7s, width 0.5s ;
}

.btn__part1{
    transform: translate(-50%, -9px);
    -webkit-transform: translate(-50%, -9px);
    -moz-transform: translate(-50%, -9px);
    -ms-transform: translate(-50%, -9px);
    -o-transform: translate(-50%, -9px);
}

.btn__part3{
    transform: translate(-50%, 7px);
    -webkit-transform: translate(-50%, 7px);
    -moz-transform: translate(-50%, 7px);
    -ms-transform: translate(-50%, 7px);
    -o-transform: translate(-50%, 7px);
}

.activo.btn__part1{
    transform: translate(-50%, -50%)rotate(45deg);
}
.activo.btn__part1{
    transform: translate(-50%, -50%)rotate(45deg);
}
.activo.btn__part3{
    transform: translate(-50%, -50%)rotate(-45deg);
}
.activo.btn__part2{
    width: 0;
}